<template>
   <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <pos-form v-if="model"
        :submit-text="pageTitle"
        :model="model"
        :isEdit="false"
        @on-submit="createModel">
    </pos-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import PosForm from './components/_Form'
import PosService from '@admin/services/PosService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'

export default {
  name: 'PosCreate',
  components: { PlaceholderForm, PosForm },
  data () {
    return {
      pageTitle: '新建POS机',
      model: null
    }
  },
  async created () {
    const { data } = await flatry(PosService.create())
    if (data) {
      this.model = data.data
    }
  },
  methods: {
    async createModel (model, success, callback) {
      const { data } = await flatry(PosService.create(model))

      if (data) {
        this.$message.success(data.msg)

        this.$router.replace({ path: '/pos/update', query: { id: data.data } })
        success()
      }

      callback()
    }
  }

}

</script>
<style lang='' scoped>

</style>
